<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        addNew: true,
        actions: [
          {
            text: 'Enable',
            body: { isActive: true },
          },
          {
            text: 'Disable',
            body: { isActive: false },
          },
          {
            text: 'Verify',
            body: { isVerified: true },
          },
          {
            text: 'Unverify',
            body: { isVerified: false },
          },
          {
            text: 'Delete',
            body: { isDeleted: true },
          },
        ],
        headers: [
          { text: 'Name', value: 'name', width: '100%' },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '50px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '50px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Default',
            value: 'default',
            width: '50px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
        action: {
          hideView: true,
          showEdit: true,
        },
      },
    };
  },
};
</script>
